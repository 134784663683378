<template>
  <div>
    <div>
      <h3 class="font-weight-bolder text-body">
        {{ STOCK_ITEMS_TITLE }}
      </h3>
    </div>
    <div class="d-flex justify-content-end">
      <button-dashed
        class="btnBorderRevert"
      >
        <feather-icon
          icon="LAddButtonIcon"
          class="cursor-pointer"
          size="34"
          @click="gotoNewPage('/catalog/create', $event, true)"
        />
      </button-dashed>

    </div>

    <l-draggable-table-list-collector
      ref="lTableRefStockItems"
      class="stock-items-table px-1"
      :is-footer="false"
      :module-name="moduleName"
      :fetched-data="fetchedRentals()"
      :table-columns="tableColumnsStockItems"
      :table-suggestion-configs="stockAutoSuggestTableColumns"
      :table-config-options="{
        endpoint: `${DEFAULT_MODULE_NAME}/stockItems`,
        data: apiParams
      }"
      :additional-object-for-selected-item="{
        amount: 1,
      }"
      :is-drag-row="false"
      autosuggest-list-item-name="name"
      collection-of-list-item-i-d="id"
      @rowHoveredHandler="rowHoveredHandler"
      @rowUnHoveredHandler="rowUnHoveredHandler"
    >
      <template #cell(actions)="{data}">
        <div>
          <div
            :id="`td-${data.id}`"
            class="d-flex"
            style="gap: 8px"
          >
            <feather-icon
              icon="LTrashIcon"
              size="30"
              style="padding: 4px"
              class="cursor-pointer border-dotted feather-trash-icon defaultIconColor"
              @click="remove(data)"
            />
            <feather-icon
              icon="LEyeIcon"
              size="24"
              class="cursor-pointer border-dotted feather-trash-icon defaultIconColor"
              @click="gotoNewPage({ name: 'home-catalog-general-info', params: {id: data.id } }, $event, true)"
            />
            <feather-icon
              icon="LNoteIcon"
              class="lightIcon cursor-pointer border-dotted featherIcon"
              size="30"
              style="padding: 4px; z-index: 1000"
            />
          </div>

        </div>
      </template>

      <template #cell(units)="{ data }">
        <div
          style="vertical-align: center"
        >
          <increment-decrement-input
            ref="incrementDecrementInputStockItems"
            :item="data"
            item-key-name-for-amount="amount"
            :on-index-comparison="data.id === getTableRowIndex"
            @onIncrementDecrementAction="handleChangeProduct"
          >
            <template #switch-off-action>
              <div style="line-height: 20px">
                {{ data.amount ? data.amount : 1 }}
              </div>
            </template>
          </increment-decrement-input>

        </div>
      </template>

      <template #cell(name)="{ data }">
        <div class="cell-row-name">
          {{ getValueFromGivenObjectByKey(data, (data) => data.name ? data.name : data.substitute.name, '' ) }}
        </div>
      </template>

      <template #cell(mpn)="{ data }">
        {{ data.hasOwnProperty('detail') ? getValueFromGivenObjectByKey(data, 'detail.mpn' ) : getValueFromGivenObjectByKey(data, 'mpn' ) }}
      </template>

      <template #cell(sku)="{ data }">
        {{ getValueFromGivenObjectByKey(data, 'sku') }}
      </template>

      <template #cell(supplier_sku)="{ data }">
        {{ getValueFromGivenObjectByKey(data, 'supplier_sku') }}
      </template>

      <template #cell(last_price)="{ data }">
        {{ formatCurrency(data) }}
      </template>

      <template #cell(last_price_date)="{ data }">
        {{ formatDate(data) }}
      </template>
      <template
        #foot(units)=""
      >
        <div
          class="d-flex justify-content-center"
          style="min-width: 230px; gap: 25px"
        >
          <span>
            {{ $t('TOTALS') }}
          </span>
          <span>
            {{ totalUnits }}
          </span>
        </div>
      </template>
    </l-draggable-table-list-collector>
  </div>
</template>
<script>

import {
  getValueFromGivenObjectByKey,
  sumTotalAmountOfGivenArrayByProperty,
} from '@core/utils/utils'
import LDraggableTableListCollector from '@/views/components/LDraggableTableListCollector.vue'
import ButtonDashed from '@core/components/b-button-dashed/ButtonDashed.vue'
import { onUnmounted } from '@vue/composition-api/dist/vue-composition-api'
import store from '@/store'
import purchaseCrModule from '@/store/main/orders/purchase-cr'
import config from './config'

export default {
  name: 'StockTable',
  components: {
    LDraggableTableListCollector,
    ButtonDashed,
  },
  props: {
    moduleName: {
      type: String,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
    apiParams: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      getTableRowIndex: null,
      totalUnits: 0,
    }
  },
  methods: {
    fetchedRentals() {
      this.totalUnits = sumTotalAmountOfGivenArrayByProperty(this.products, 'amount')
      return this.products
    },
    handleChangeProduct() {
      this.$emit('handleChangeProduct', this.products)
      this.totalUnits = sumTotalAmountOfGivenArrayByProperty(this.products, 'amount')
    },
    rowHoveredHandler(rowData) {
      const { id, amount } = rowData
      this.getTableRowIndex = id
      this.$refs.incrementDecrementInputStockItems.setValue(amount ?? 1)
    },
    rowUnHoveredHandler() {
      this.getTableRowIndex = null
    },
    remove({ id }) {
      this.$emit('handleChangeProduct', this.products.filter(item => item.id !== id))
      this.totalUnits = sumTotalAmountOfGivenArrayByProperty(this.products, 'amount')
    },
  },
  setup() {
    const DEFAULT_MODULE_NAME = 'purchase-cr'

    // Register on enter section
    if (!store.hasModule(DEFAULT_MODULE_NAME)) store.registerModule(DEFAULT_MODULE_NAME, purchaseCrModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DEFAULT_MODULE_NAME)) store.unregisterModule(DEFAULT_MODULE_NAME)
    })

    const {
      STOCK_ITEMS_TITLE,
      tableColumnsStockItems,
      stockAutoSuggestTableColumns,
      formatCurrency,
      formatDate,
    } = config

    return {
      STOCK_ITEMS_TITLE,
      tableColumnsStockItems,
      getValueFromGivenObjectByKey,
      sumTotalAmountOfGivenArrayByProperty,
      stockAutoSuggestTableColumns,
      DEFAULT_MODULE_NAME,
      formatCurrency,
      formatDate,
    }
  },
}
</script>
<style lang="scss">
.stock-items-table {

  tbody td {
    vertical-align: top !important;
  }

  .line-btn  {
    display: none;
  }

  table td[aria-colindex="6"] {
    padding: 0 !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 38px;
    & > div {
      min-height: 100%;
    }
  }

}
</style>
