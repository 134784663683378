var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('h3',{staticClass:"font-weight-bolder text-body"},[_vm._v(" "+_vm._s(_vm.STOCK_ITEMS_TITLE)+" ")])]),_c('div',{staticClass:"d-flex justify-content-end"},[_c('button-dashed',{staticClass:"btnBorderRevert"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"LAddButtonIcon","size":"34"},on:{"click":function($event){return _vm.gotoNewPage('/catalog/create', $event, true)}}})],1)],1),_c('l-draggable-table-list-collector',{ref:"lTableRefStockItems",staticClass:"stock-items-table px-1",attrs:{"is-footer":false,"module-name":_vm.moduleName,"fetched-data":_vm.fetchedRentals(),"table-columns":_vm.tableColumnsStockItems,"table-suggestion-configs":_vm.stockAutoSuggestTableColumns,"table-config-options":{
      endpoint: (_vm.DEFAULT_MODULE_NAME + "/stockItems"),
      data: _vm.apiParams
    },"additional-object-for-selected-item":{
      amount: 1,
    },"is-drag-row":false,"autosuggest-list-item-name":"name","collection-of-list-item-i-d":"id"},on:{"rowHoveredHandler":_vm.rowHoveredHandler,"rowUnHoveredHandler":_vm.rowUnHoveredHandler},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(ref){
    var data = ref.data;
return [_c('div',[_c('div',{staticClass:"d-flex",staticStyle:{"gap":"8px"},attrs:{"id":("td-" + (data.id))}},[_c('feather-icon',{staticClass:"cursor-pointer border-dotted feather-trash-icon defaultIconColor",staticStyle:{"padding":"4px"},attrs:{"icon":"LTrashIcon","size":"30"},on:{"click":function($event){return _vm.remove(data)}}}),_c('feather-icon',{staticClass:"cursor-pointer border-dotted feather-trash-icon defaultIconColor",attrs:{"icon":"LEyeIcon","size":"24"},on:{"click":function($event){return _vm.gotoNewPage({ name: 'home-catalog-general-info', params: {id: data.id } }, $event, true)}}}),_c('feather-icon',{staticClass:"lightIcon cursor-pointer border-dotted featherIcon",staticStyle:{"padding":"4px","z-index":"1000"},attrs:{"icon":"LNoteIcon","size":"30"}})],1)])]}},{key:"cell(units)",fn:function(ref){
    var data = ref.data;
return [_c('div',{staticStyle:{"vertical-align":"center"}},[_c('increment-decrement-input',{ref:"incrementDecrementInputStockItems",attrs:{"item":data,"item-key-name-for-amount":"amount","on-index-comparison":data.id === _vm.getTableRowIndex},on:{"onIncrementDecrementAction":_vm.handleChangeProduct},scopedSlots:_vm._u([{key:"switch-off-action",fn:function(){return [_c('div',{staticStyle:{"line-height":"20px"}},[_vm._v(" "+_vm._s(data.amount ? data.amount : 1)+" ")])]},proxy:true}],null,true)})],1)]}},{key:"cell(name)",fn:function(ref){
    var data = ref.data;
return [_c('div',{staticClass:"cell-row-name"},[_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(data, function (data) { return data.name ? data.name : data.substitute.name; }, '' ))+" ")])]}},{key:"cell(mpn)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(data.hasOwnProperty('detail') ? _vm.getValueFromGivenObjectByKey(data, 'detail.mpn' ) : _vm.getValueFromGivenObjectByKey(data, 'mpn' ))+" ")]}},{key:"cell(sku)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(data, 'sku'))+" ")]}},{key:"cell(supplier_sku)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.getValueFromGivenObjectByKey(data, 'supplier_sku'))+" ")]}},{key:"cell(last_price)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(data))+" ")]}},{key:"cell(last_price_date)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.formatDate(data))+" ")]}},{key:"foot(units)",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"min-width":"230px","gap":"25px"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('TOTALS'))+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.totalUnits)+" ")])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }